@import "../../utils/Variables.scss";

.main {
    width: 100vw;
    height: 100vh;
    background-color: $faint;
    display: flex;
    justify-content: center;
    align-items: center;

    & .login-form {
        width: 30%;
        height: 90%;
        margin: auto;
        @include box-shadow;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 0 50px;

        & .logo-holder {
            flex: 25%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & img{
                width: 60px;
                height: 60px;
                object-fit: contain;
                object-position: center;
            }

            & h2 {
                color: $primary;
            }

            & p {
                color: $dark-gray;
                font-size: 1.5rem;
            }

            @media screen and (max-width: 1200px) {
                & p{
                    font-size: 1.2rem;
                }

                & img{
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                    object-position: center;
                }

                & h2{
                    font-size: 1.5rem;
                }
            }
        }

        & .info-holder {
            flex: 75%;
            display: flex;
            flex-direction: column;

            & .info-con {
                flex: 20%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                & h1 {
                    color: $dark;
                }

                & p {
                    color: $dark-gray;
                    font-size: 1.2rem;
                }

                @media screen and (max-width: 1200px) {
                    & h1{
                        font-size: 1.5rem;
                    }

                    & p{
                        font-size: 1rem;
                    }
                }
            }

            & .detials-con {
                flex: 80%;

                & input {
                    display: block;
                    width: 100%;
                    height: 60px;
                    margin-top: 30px;
                    background-color: $gray;
                    border-radius: 10px;
                    transition: border-color 0.3s;
                    outline: none;
                    border: 3px solid $gray;
                    font-size: 1.1rem;
                    padding: 0 20px;
                    color: $dark;
                    font-weight: 500;
                }

                & input:focus {
                    border: 3px solid $primary;
                    outline: none;
                }

                & input:first-child {
                    margin-top: 0;
                }

                & input::placeholder {
                    font-size: 1rem;
                    font-weight: 500;
                    color: $dark-gray;
                }

                & button {
                    width: 100%;
                    height: 60px;
                    margin-top: 20px;
                    background-color: $primary;
                    border-radius: 10px;
                    transition: 0.2s;
                    outline: none;
                    border: 3px solid $gray;
                    font-size: 1rem;
                    padding: 0 20px;
                    cursor: pointer;

                    color: $faint;
                    font-weight: bold;
                    font-size: 1.2rem;
                }

                & button:hover {
                    background-color: $secondary;
                }

                & p {
                    margin-top: 20px;
                    text-align: center;
                    color: $dark;
                    font-size: 1.2rem;
                    font-weight: 500;

                    & span {
                        color: $primary;
                        font-weight: bold;
                        cursor: pointer;
                        transition: 0.2s;
                    }

                    & span:hover {
                        text-decoration: underline;
                    }
                }

                .forgot-pass{
                    text-align: end;
                    color: $dark-gray;
                    cursor: pointer;
                    font-size: 1rem;
                }
                .forgot-pass:hover{
                    color: $primary;
                }

                @media screen and (max-width: 1200px) {
                    & p{
                        font-size: 0.9rem;
                    }
                    & input{
                        padding: 0 10px;
                    }
                }
            }

        }

    }

    @media screen and (max-width: 1200px) {
        .login-form {
            width: 90%;
            height: 90%;
            padding: 30px;
        }
    }
    @media screen and (max-height: 900px) {
        .login-form {
            height: 100%;
        }
    }
}