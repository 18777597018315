@import "../Variables.scss";

.loader-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    @include box-shadow;
    background-color: $faint;
    padding: 10px;
    border-radius: 1rem;

    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    @include loader-animation;
    transition: .3s;

    img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        object-position: center;
        animation: loading-animation 4s linear infinite;
    }

    p {
        font-size: 1.2rem;
        color: $dark-gray;
        text-align: center;
    }
}