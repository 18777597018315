$primary: #ffbb01;
$secondary: #fe9700;
$faint: #fdfffd;
$white: #ffffff;
$dark: #040204;
$gray: #f8f8f8;
$dark-gray: #8c8d8f;

@mixin box-shadow {
    box-shadow: 0 0 15px 2px rgba(214, 215, 220, 0.5);
}

@mixin toast-shadow {
    box-shadow: 0 0 15px 5px $dark-gray;
}

@mixin loader-animation {
    @keyframes loading-animation {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(-25deg);
        }

        50% {
            transform: rotate(0deg);
        }

        75% {
            transform: rotate(25deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }
}

@mixin toast-animation {
    @keyframes toast-time-animation {
        0% {
            width: 100%;
        }

        100% {
            width: 0;
        }
    }
}