@import "../Variables.scss";

.toast-holder {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    @include toast-shadow;
    background-color: $faint;
    padding: 10px 0;
    border-radius: 5px;
    transition: 0.3s;

    width: 30%;
    height: fit-content;

    display: flex;
    align-items: center;

    & p{
        font-size: 0.9rem;
        color: $dark;
        padding-left: 10px;
    }

    @include toast-animation;

    .toast-line{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5px;
        background-color: $primary;
        border-radius: 0 0 0 5px;
        animation: toast-time-animation 3s linear infinite;
    }

    
}

@media screen and (max-width: 1200px) {
    .toast-holder {
        width: 90%;
    }
}